import { format as _format } from "date-fns";
import { nl } from "date-fns/locale";

const options = {
  locale: nl,
};

/**
 * Formats a date.
 * @param {number | Date} date
 * @param {string} format
 */
export function format(date, format = "d MMMM Y") {
  return _format(date, format, options);
}
